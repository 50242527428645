<template>
  <v-container fluid class="pa-0 ma-0 bg fill-height" fill-height >
    <Header />
    <v-container align="center" justify="center" class="mt-6 rounded-t-lg bodq" id="cajita">
      <v-card>
        <v-card-title>Nivel de impacto<v-spacer></v-spacer> 🤓</v-card-title>
        <v-card-subtitle>Descargar.</v-card-subtitle>
        <v-card-text>
          <p>Durante este autodiagnóstico hemos valorado la probabilidad de que alguna agresión se concrete, no obstante no podemos definir que tanto impacto podría t si alguna de estas agresiones sucediera. Por esto te animamos a que tomes un tiempo para reflexionar sobre que tanto puede afectar cada una de las agresiones en términos de integridad física, operacional, reputación, etc. <br>
          Al final un cruce entre la probabilidad y el impacto te proporcionara una mayor idea de los siguientes pasos que debes tomar para mejorar tu seguridad digital.</p>

          <v-form ref="form" lazy-validation>
            <v-row>
              <template v-for="(agresion, index) in dataReporte.agresiones" >
                <!-- <v-col cols="12"  :key="agresion.id"> -->
                  <v-col cols="12" :key="index" :id="index">
                  <v-card  :style="'border-color:' + agresion.color" class="card-agresion" elevation="2">
                    <v-card-title>{{ agresion.texto }}
                      <v-spacer></v-spacer>
                      <small><span>Score de probabilidad </span> <span class="">{{ Math.floor(Math.random() * 10) + 1 }}</span></small>
                      <v-card-subtitle><span v-html="agresion.subtexto"></span></v-card-subtitle>
                    </v-card-title>
                    <v-slider v-model="modelSilide[index]" 
                      :color="colors[modelSilide[index]]"
                      :tick-labels="ticksLabels"
                      :max="3"
                      step="1"
                      ticks="always"
                      tick-size="4"
                      thumb-label="always"
                    >
                      <template v-slot:thumb-label="{ value }">
                          {{impactEmojis[value]}}
                      </template>
                    </v-slider>
                  </v-card>
                </v-col>
              </template>


                  <!-- {{agresion}} -->
            </v-row>
          </v-form>
          
          <v-row>
            <v-col class="text-right">
              <v-btn x-large block elevation="0" color="primary" @click="nextModule">Mostrar reporte <v-icon>mdi-arrow-right-thick</v-icon></v-btn>
            </v-col>
          </v-row>


        </v-card-text>
        <v-card-actions class="justify-center">
          <p class="text--secondary text-center"><small>Durante el autodiagnóstico los datos son guardados en la memoria local de tu navegador. Nosotros no guardamos, enviamos o recopilamos tus datos no obstante si lo deseas al final puedes compartir tus resultados anónimamente o con un correo para seguimiento por nuestra parte.</small></p>
        </v-card-actions>
      </v-card>
      
    </v-container>
    <Footer />
  </v-container>
</template>

<script>
// import { mapState } from 'vuex'
import Header from '@/layout/Header'
import Footer from '@/layout/Footer'


export default {
  name: 'Reporte',
  data () {
    return {
      next: {
      name: 'Reporte', 
        params: {
          category: this.$route.params.category,
          module: 'agresiones', 
          agresion: 'impacto'
        },
      },
      
      dataReporte: {},
      modelSilide: [],
      ticksLabels: [
        'Impacto nulo',
        'Poco Impacto',
        'Impacto medio',
        'Impacto elevado',
        'Impacto alto',
      ],
      colors: [
        'primary',
        '#A3669D',
        '#CB5469',
        'red',
      ],

      impactEmojis: ['😊','😢','😭','😱'],
    } 
  },
  created() {
    this.construReporte()
    this.getData()
    window.scrollTo(0,0)
  },
  methods: {
    construReporte() {
      this.dataReporte.perfil = JSON.parse(localStorage.getItem('my-quiz-perfil'))
      this.dataReporte.habitos = JSON.parse(localStorage.getItem('my-quiz-habitos'))
      this.dataReporte.agresiones = JSON.parse(localStorage.getItem('my-quiz-agresiones'))
    },

    nextModule() {
      if(this.$refs.form.validate()) {
        // localStorage.setItem('my-quiz-'+this.$route.params.module, JSON.stringify(this.respuestas))
        // console.log(JSON.parse(localStorage.getItem('my-quiz-perfil')))

        this.$router.push(this.next)
      } else {
        console.log('Error al enviar ModuloPerfil')
      }
    },
  },
  components: {
    Header, Footer
  },
}
</script>
<style scoped>
.bodq {
  background-color: rgba(255, 255, 255, .5);
}
.bg {
  content: "";
  background-image: url(~@/assets/head_2.png);
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  height: 308px;
}
.bg::before {
  content: "";
  background-color: rgba(255,255,255, 0.1);
}

.card-agresion {
  border-style: solid;
  border-width: 1.5ex 0.5ex 0.5ex !important;
}
</style>
